import React, { useContext, useEffect, useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import { ADcontext } from "../../context/adContext";
import DropdowNavBar, { DropdowNavBarProfile } from "../atoms/DropdowNavBar";
import { GoogleButton } from "../UI/google-button";

export default function NavBar() {
   const { state } = useContext(ADcontext);
   const [permissions, setPermissions] = useState(false);

   useEffect(() => {
      const rol = Number(localStorage.getItem("rol"));

      if (state.isAuth && rol === 10) {
         setPermissions(true);
      }
   }, [state.isAuth]);

   return (
      <header id="header" className="header p-2">
         <Container className="container-custom">
            <Navbar expand="lg">
               <>
                  <Link
                     to={"https://www.nexpost.ai/"}
                     className="navbar-brand text-decoration-none logo d-flex align-items-center"
                  >
                     <img src="assets/img/logo.png" alt="Logo" />
                     <span>NexPost</span>
                  </Link>
                  <Navbar.Toggle
                     className="btn-0 py-3 border-0"
                     aria-controls="basic-navbar-nav"
                  >
                     <i
                        style={{ color: "#00ca90" }}
                        className="fa-xl fa-solid fa-bars"
                     ></i>
                  </Navbar.Toggle>

                  <Navbar.Collapse id="basic-navbar-nav">
                     <Nav className="ms-auto">
                        {state.isAuth ? (
                           <>
                              <NavLink
                                 style={{ color: "#00ca90" }}
                                 className="nav-link fw-bold fs-6 text-center"
                                 to="/home"
                              >
                                 Home
                              </NavLink>

                              <div className="mb-3 mb-md-0 d-flex justify-content-center">
                                 <DropdowNavBar />
                              </div>

                              <div className="mb-3 mb-md-0 d-flex justify-content-center">
                                 <DropdowNavBarProfile
                                    handlrePermission={() =>
                                       setPermissions(false)
                                    }
                                 />
                              </div>
                           </>
                        ) : (
                           // <button
                           //   className="rounded-pill px-4 ms-3 fw-bold btn btn-primary-s text-dark"
                           //   onClick={() => navigate("/login/")}
                           // >
                           //   Login
                           // </button>
                           <GoogleButton />
                        )}
                     </Nav>
                  </Navbar.Collapse>
               </>
            </Navbar>
         </Container>
      </header>
   );
}
