import React, { Suspense, useEffect } from "react";
import NavBar from "../../components/organisms/_NavBar";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import routes from "../../routes";
import Footer from "../organisms/_Footer";
import { Loading } from "../UI/loading";
import useAuthRedirect from "../../services/auth.service";

export default function DefaultLayout() {
  useAuthRedirect();
  const location = useLocation();

  useEffect(() => {
    const titles = {
      '/home': 'NexPost - Home',
      '/rsa-google': 'NexPost - RSA Google',
      '/post-linkedin': 'NexPost - POST LinkedIn',
      '/post-reddit': 'NexPost - POST Reddit',
      '/blog': 'NexPost - Blog',
      '/write': 'NexPost - Write',
      '/blog/:id/': 'NexPost - View',
      '/post/edit/:id/': 'NexPost - Edit',
      '/register': 'NexPost - Register',
      '/settings': 'NexPost - Settings',
    };

    document.title = titles[location.pathname] ?? 'NexPost';
  }, [location]);
  return (
    <div className="wrapper">
      <NavBar />
      <Suspense fallback={<Loading />}>
        <Routes>
          {routes.map((route, idx) => {
            return (
              route.element && (
                <Route
                  key={idx}
                  path={route.path}
                  exact={true}
                  name={route.name}
                  element={<route.element />}
                />
              )
            );
          })}
          <Route path="/" element={<Navigate to="home" replace />} />
        </Routes>
      </Suspense>

      <Footer />
    </div>
  );
}
