import React from "react";

export default function Footer() {
  return (
    <footer id="footer" className="footer py-3">
      {/* <div className="footer-top">
        <div className="container">
          <div className="row gy-4">
            <div className="col-lg-5 col-md-12 footer-info">
              <a href="index.html" className="logo d-flex align-items-center">
                <img src="assets/img/logo.png" alt="" />
                <span>RSA </span>
              </a>
              <p>
                Cras fermentum odio eu feugiat lide par naso tierra. Justo eget
                nada terra videa magna derita valies darta donna mare fermentum
                iaculis eu non diam phasellus.
              </p>
              <div className="social-links mt-3">
                <a href="/" className="twitter">
                  <i className="bi bi-twitter"></i>
                </a>
                <a href="/" className="facebook">
                  <i className="bi bi-facebook"></i>
                </a>
                <a href="/" className="instagram">
                  <i className="bi bi-instagram"></i>
                </a>
                <a href="/" className="linkedin">
                  <i className="bi bi-linkedin"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="container">
        <p className="copyright">
          © NexPost AI, 2024. All rights reserved.{" "}
          <a
            href="mailto:hello@nexpost.ai"
            style={{ color: "#00ca90", textDecoration: "none" }}
          >
            hello@nexpost.ai
          </a>
        </p>
      </div>
    </footer>
  );
}
